import {useCardModal} from '@businessComponents/modal';

import {moneyBackground} from './me.variable';
import {LayoutChangeEvent, View, StyleSheet} from 'react-native';
import theme from '@style';
import LazyImage, {LazyImageBackground} from '@basicComponents/image';
import React from 'react';
import Text from '@basicComponents/text';
import {goTo, toPriceStr} from '@utils';
import globalStore from '@/services/global.state';
import {IUserInfo} from '@services/global.service';
import LinearGradient from '@basicComponents/linear-gradient';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {useTranslation} from 'react-i18next';
import {
  rechargeIcon,
  withdrawIcon,
} from '@/common-pages/wallet/wallet.variable';
const refreshIcon = require('@assets/icons/me/refresh.webp');
export interface MeAmountProps {
  login?: boolean;
  onLayout?: (e: LayoutChangeEvent) => void;
  topBlockWidth?: number;
  user?: IUserInfo;
  onRecharge?: () => void;
  onWithdraw?: () => void;
  onRefresh?: () => void;
}

const MeAmount: React.FC<MeAmountProps> = ({
  login,
  onLayout,
  topBlockWidth,
  user,
  onRecharge,
  onWithdraw,
  onRefresh,
}) => {
  const {i18n} = useTranslation();
  const {renderModal, show} = useCardModal();

  const showRechargeModal = () => {
    show({
      icon: rechargeIcon,
      title: i18n.t('me.tip.cashTitle'),
      content: i18n.t('me.tip.cashContent'),
    });
  };

  const showDrawModal = () => {
    show({
      icon: withdrawIcon,
      title: i18n.t('me.tip.withdrawTitle'),
      content: i18n.t('me.tip.withdrawContent'),
    });
  };

  return (
    <View onLayout={onLayout}>
      <LazyImageBackground
        occupancy={'transparent'}
        imageUrl={moneyBackground}
        width={topBlockWidth}
        height={((topBlockWidth || 375) / 335) * 152}
        style={[theme.margin.tbm]}>
        <View
          style={[
            styles.wallet,
            theme.flex.row,
            theme.flex.between,
            theme.flex.centerByCol,
            theme.padding.lrm,
          ]}>
          <NativeTouchableOpacity onPress={() => goTo('Wallet')}>
            <View style={[theme.flex.col, theme.padding.tbxxl]}>
              <View
                style={[
                  theme.flex.row,
                  theme.flex.centerByCol,
                  theme.padding.leftm,
                ]}>
                <LazyImage
                  occupancy={'transparent'}
                  imageUrl={require('@assets/icons/me/wallet.webp')}
                  width={16}
                  height={16}
                />
                <Text
                  style={[theme.margin.lefts, theme.font.fs, theme.font.white]}>
                  {i18n.t('me.money.totalWallet')}
                </Text>
              </View>

              <Text
                fontFamily="fontDin"
                style={[
                  theme.font.fxl,
                  theme.font.white,
                  theme.padding.leftm,
                  theme.margin.topxxs,
                ]}>
                {login
                  ? toPriceStr(user?.userBalance || 0, {
                      thousands: true,
                      spacing: true,
                      currency: globalStore.currency,
                    })
                  : '********'}
              </Text>
            </View>
          </NativeTouchableOpacity>
          <NativeTouchableOpacity
            onPress={onRefresh}
            style={[theme.margin.rightm]}>
            <LazyImage
              occupancy={'transparent'}
              imageUrl={refreshIcon}
              width={theme.iconSize.xxl}
              height={theme.iconSize.xxl}
            />
          </NativeTouchableOpacity>
        </View>
        <LinearGradient
          start={{x: 0, y: 0}}
          end={{x: 0, y: 1}}
          colors={['rgba(0, 0, 0, 0.2)', 'rgba(0, 0, 0, 0)']}
          style={[theme.padding.lrm]}>
          <View
            style={[
              theme.flex.row,
              theme.flex.between,
              theme.padding.lrm,
              theme.padding.tbs,
            ]}>
            <View style={[theme.flex.col]}>
              <NativeTouchableOpacity activeOpacity={1} onPress={onRecharge}>
                <View style={[theme.flex.row, theme.flex.centerByCol]}>
                  <LazyImage
                    occupancy={'transparent'}
                    imageUrl={require('@assets/icons/me/recharge-white.webp')}
                    width={16}
                    height={16}
                  />
                  <Text
                    style={[theme.font.white, theme.margin.lefts]}
                    fontSize={theme.fontSize.xs}>
                    {i18n.t('me.money.cashBalance')}
                  </Text>
                  {/* TODO 这里会引起报错,button不能是另一个botton的子元素 */}
                  <NativeTouchableOpacity
                    activeOpacity={1}
                    style={[theme.margin.lefts]}
                    onPress={showRechargeModal}>
                    <LazyImage
                      occupancy={'transparent'}
                      imageUrl={require('@assets/icons/me/help.webp')}
                      width={16}
                      height={16}
                    />
                  </NativeTouchableOpacity>
                </View>
                <Text
                  fontFamily="fontDin"
                  style={[theme.font.fl, theme.font.white, theme.margin.tops]}>
                  {login
                    ? toPriceStr(user?.rechargeAmount || 0, {
                        thousands: true,
                        spacing: true,
                      })
                    : '******'}
                </Text>
              </NativeTouchableOpacity>
            </View>
            <View style={[theme.flex.col]}>
              <NativeTouchableOpacity activeOpacity={1} onPress={onWithdraw}>
                <View style={[theme.flex.row, theme.flex.centerByCol]}>
                  <LazyImage
                    occupancy={'transparent'}
                    imageUrl={require('@assets/icons/me/withdraw-white.webp')}
                    width={16}
                    height={16}
                  />
                  <Text
                    style={[theme.font.white, theme.margin.lefts]}
                    fontSize={theme.fontSize.xs}>
                    {i18n.t('me.money.withdrawAmount')}
                  </Text>
                  <NativeTouchableOpacity
                    activeOpacity={1}
                    style={[theme.margin.lefts]}
                    onPress={showDrawModal}>
                    <LazyImage
                      occupancy={'transparent'}
                      imageUrl={require('@assets/icons/me/help.webp')}
                      width={16}
                      height={16}
                    />
                  </NativeTouchableOpacity>
                </View>
                <Text
                  fontFamily="fontDin"
                  style={[theme.font.fl, theme.font.white, theme.margin.tops]}>
                  {login
                    ? toPriceStr(user?.canWithdrawAmount || 0, {
                        thousands: true,
                        spacing: true,
                      })
                    : '******'}
                </Text>
              </NativeTouchableOpacity>
            </View>
          </View>
        </LinearGradient>
      </LazyImageBackground>
      {renderModal}
    </View>
  );
};

const styles = StyleSheet.create({
  wallet: {
    borderBottomColor: '#723BB9',
    borderBottomWidth: 1,
  },
});

export default MeAmount;
