import theme from '@style';
import {homeServiceStyle} from '../home.style';
import LazyImage from '@basicComponents/image';
import React, {useMemo} from 'react';
import TouchableOpacity from '@basicComponents/touchable-opacity';
import {BasicObject} from '@types';
import globalStore from '@/services/global.state';
import {goCS} from '@/utils';
const HomeService = () => {
  const iconShadow = useMemo(() => {
    let shadow = {} as BasicObject;
    if (globalStore.isWeb) {
      shadow = {
        ...shadow,
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
      };
    } else {
      shadow = {
        ...shadow,
        shadowColor: 'rgba(0, 0, 0, 0.25)',
        shadowOffset: {width: 0, height: 4},
        shadowOpacity: 1,
        shadowRadius: 4,
        elevation: 12,
      };
    }
    return shadow;
  }, []);

  return (
    <TouchableOpacity
      containerStyle={[
        theme.position.abs,
        theme.background.primary,
        homeServiceStyle.service,
        theme.flex.center,
        iconShadow,
      ]}
      onPress={goCS}>
      <LazyImage
        width={46}
        height={46}
        radius={46}
        imageUrl={require('@components/assets/icons/service.webp')}
      />
    </TouchableOpacity>
  );
};

export default HomeService;
