import Card, {lightLinear} from '@basicComponents/card';
import LazyImage from '@basicComponents/image';
import TouchableOpacity from '@basicComponents/touchable-opacity';
import globalStore from '@/services/global.state';
import theme from '@/style';
import {goTo} from '@/utils';
import React, {forwardRef} from 'react';
import {View, ViewProps, StyleSheet} from 'react-native';
import {useTranslation} from 'react-i18next';

const HomeColor = forwardRef<View, ViewProps>((props, ref) => {
  const {style, ...otherProp} = props;
  const colorWidth = globalStore.screenWidth - 2 * theme.paddingSize.l;
  const {i18n} = useTranslation();
  return (
    <View
      ref={ref}
      style={[
        {
          minHeight: (colorWidth / 351) * 128,
        },
        style,
      ]}
      {...otherProp}>
      <Card>
        <Card.Title
          icon={{
            url: require('@assets/icons/home/little.webp'),
            width: 8,
            height: 8,
          }}
          style={[styles.title]}
          linear={lightLinear}
          title={i18n.t('home.color.title')}
        />
        <View style={[theme.padding.lrl, theme.padding.btml]}>
          <TouchableOpacity
            onPress={() => {
              goTo('GameWebView', {
                type: 'color',
              });
              // postMessage('router:/pages/colorPrediction/colorPrediction');
            }}>
            <LazyImage
              width={colorWidth}
              height={(colorWidth / 351) * 128}
              imageUrl={require('@assets/icons/home/color.webp')}
            />
          </TouchableOpacity>
        </View>
      </Card>
    </View>
  );
});

const styles = StyleSheet.create({
  title: {
    height: 52,
  },
});

export default HomeColor;
