import NavTitle from '@/components/basic/nav-title';
import Spin from '@/components/basic/spin';
import globalStore from '@/services/global.state';
import theme from '@/style';
import {BasicObject, NavigatorScreenProps} from '@/types';
import {goBack} from '@/utils';
import React from 'react';
import {View} from 'react-native';
import {WebView, WebViewMessageEvent} from 'react-native-webview';

const WebViewScreen = (props: NavigatorScreenProps) => {
  const params = props.route.params as BasicObject;
  const [url, setUrl] = React.useState(params?.url);
  const [pageLoading, setPageLoading] = React.useState(true);

  const handleMessage = (e: WebViewMessageEvent | MessageEvent) => {
    const data = globalStore.isWeb
      ? (e as MessageEvent).data
      : (e as WebViewMessageEvent).nativeEvent.data;
    processData(data);
  };
  const processData = (data: string | BasicObject) => {
    if (!data) {
      return;
    }
    if (typeof data === 'string') {
      if (data === 'pageLoading:show') {
        setPageLoading(true);
        return;
      }
      if (data === 'pageLoading:hide') {
        setPageLoading(false);
        return;
      }
      if (data === 'globalLoading:show') {
        globalStore.globalLoading.next(true);
        return;
      }
      if (data === 'globalLoading:hide') {
        globalStore.globalLoading.next(false);
        return;
      }
    }
  };
  React.useEffect(() => {
    setUrl(params?.url);
  }, [params?.url]);

  return (
    <View style={[theme.fill.fill]}>
      <NavTitle title="Demo" onBack={goBack} />
      <Spin loading={pageLoading} style={[theme.flex.flex1]}>
        <WebView
          style={[theme.fill.fill]}
          onMessage={handleMessage}
          source={{uri: url || 'https://h5-games.pages.de'}}
        />
      </Spin>
    </View>
  );
};

export default WebViewScreen;
