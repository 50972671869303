import {Platform} from 'react-native';
import RNConfig from 'react-native-config';

const IS_WEB = Platform.OS === 'web';

const ENV_CONFIG = (IS_WEB ? process.env : RNConfig) as {
  REACT_APP_ENV: 'dev' | 'test' | 'uat' | 'prod';
  REACT_APP_API_BASE_URL: string;
  REACT_APP_API_UNI_URL: string;
  REACT_APP_API_INDUSWIN_URL?: string;
  REACT_APP_API_SCRATCH_URL?: string;
  REACT_APP_API_H5GAMES_URL?: string;
  [k: string]: string | number | undefined;
};

class Config {
  private _appToken = 'cqgcd6lp6jgg'; // singam h5
  // private _appToken = IS_WEB
  //   ? 'cqgcd6lp6jgg' // singam h5
  //   : 'igrlgvw76zuo'; // singam rn01;

  private _codePushKey: string;
  get appToken() {
    return this._appToken;
  }
  private _baseUrl: string;
  get baseUrl() {
    return this._baseUrl;
  }
  private _uniUrl: string;
  get uniUrl() {
    return this._uniUrl;
  }
  private _induswinUrl: string | undefined;
  get induswinUrl() {
    return this._induswinUrl;
  }
  private _reactH5Url = 'https://h5-games.pages.dev';
  get reactH5Url() {
    return this._reactH5Url;
  }
  private _scratchUrl: string | undefined;
  get scratchUrl() {
    return this._scratchUrl;
  }
  get codePushKey() {
    return this._codePushKey;
  }
  private _moengageAppId = 'Q60RICPHDSGXDX7PXH3NCE7K';

  get moengageAppId() {
    return this._moengageAppId;
  }

  constructor() {
    this._baseUrl = ENV_CONFIG.REACT_APP_API_BASE_URL;
    this._uniUrl =
      ENV_CONFIG.REACT_APP_API_UNI_URL ||
      'https://singam-uni-prod-view.pages.dev';
    this._induswinUrl =
      ENV_CONFIG.REACT_APP_API_INDUSWIN_URL ||
      ENV_CONFIG.REACT_APP_API_BASE_URL;
    this._reactH5Url = ENV_CONFIG.REACT_APP_API_H5GAMES_URL || this._reactH5Url;
    this._moengageAppId = this._moengageAppId;
    this._scratchUrl = ENV_CONFIG.REACT_APP_API_SCRATCH_URL;
    this._codePushKey =
      ENV_CONFIG.REACT_APP_ENV === 'prod'
        ? '5naQjnOyKbgw_rtH5Wel30umaDgYv2qxoNvCV'
        : ENV_CONFIG.REACT_APP_ENV === 'uat'
        ? 'eij_AQ1ONgfae30PHCTkTKl4sSiUNfX_8oOUv'
        : ENV_CONFIG.REACT_APP_ENV === 'test'
        ? 'qvLFQgxtWE5xfTxeqPs8FlBPSRgc9LKqgRmq3'
        : '';
  }
}

export const envConfig = new Config();
export default envConfig;
