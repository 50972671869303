import React from 'react';
import LinearGradient from '@basicComponents/linear-gradient';
import theme from '@style';
import {StyleSheet, View} from 'react-native';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import LazyImage from '@/components/basic/image';
import {useTranslation} from 'react-i18next';
import Text from '@basicComponents/text';

const {flex, padding, font, margin, background} = theme;

export interface MeRowMenuProps {
  onRecharge?: () => void;
  onWithdraw?: () => void;
  onTransfer?: () => void;
}

const MeRowMenu: React.FC<MeRowMenuProps> = ({
  onRecharge,
  onWithdraw,
  onTransfer,
}) => {
  const {calcActualSize} = useScreenSize();
  const styles = StyleSheet.create({
    bottomNav: {
      height: calcActualSize(80),
    },
    bottomNavItem: {
      width: calcActualSize(104),
    },
    upIcon: {
      width: calcActualSize(40),
      height: calcActualSize(40),
    },
  });
  const {i18n} = useTranslation();
  return (
    <LinearGradient
      start={{x: 0, y: 0}}
      end={{x: 0, y: 1}}
      colors={['#fff', '#fff0']}
      style={[padding.lrl, background.lightGrey]}>
      <View style={[flex.row, styles.bottomNav, flex.around]}>
        <NativeTouchableOpacity
          style={[flex.center, styles.bottomNavItem]}
          onPress={onRecharge}>
          <View style={[flex.center]}>
            <View style={[styles.upIcon, flex.center]}>
              <LazyImage
                occupancy={'transparent'}
                imageUrl={require('@assets/icons/wallet/recharge.webp')}
                width={calcActualSize(28)}
                height={calcActualSize(28)}
              />
            </View>
            <Text style={[font.fs, font.second, margin.topxxs]}>
              {i18n.t('me.bottom.recharge')}
            </Text>
          </View>
        </NativeTouchableOpacity>
        <NativeTouchableOpacity
          style={[flex.center, styles.bottomNavItem]}
          onPress={onWithdraw}>
          <View style={[flex.center]}>
            <View style={[styles.upIcon, flex.center]}>
              <LazyImage
                occupancy={'transparent'}
                imageUrl={require('@assets/icons/me/withdraw.webp')}
                width={calcActualSize(28)}
                height={calcActualSize(28)}
              />
            </View>
            <Text style={[font.fs, font.second, margin.topxxs]}>
              {i18n.t('me.bottom.withdraw')}
            </Text>
          </View>
        </NativeTouchableOpacity>
        <NativeTouchableOpacity
          style={[flex.center, styles.bottomNavItem]}
          onPress={onTransfer}>
          <View style={[flex.center]}>
            <LazyImage
              occupancy={'transparent'}
              imageUrl={require('@assets/icons/me/transfer-gif.gif')}
              width={calcActualSize(40)}
              height={calcActualSize(40)}
            />
            <Text style={[font.fs, font.second, margin.topxxs]}>
              {i18n.t('me.bottom.transfer')}
            </Text>
          </View>
        </NativeTouchableOpacity>
      </View>
    </LinearGradient>
  );
};

export default MeRowMenu;
