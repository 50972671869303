import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import styles from '../home.style';
import React, {useEffect, useRef, useState} from 'react';
import {View, Image} from 'react-native';
import Text from '@basicComponents/text';
import Tag from '@basicComponents/tag';
import {NoticeMap} from '../home.type';
import {useLuckySpinModal} from '@/common-pages/luckyspin/luckyspin.hooks';
import {useLotteryModal} from '../lottery.hooks';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {postGetFreeLottery} from '../home.service';
import {goTo, toAgentApply} from '@/utils';
import theme from '@style';
import {postSpinConfig} from '@/common-pages/luckyspin/luckyspin.service';

const getNavTag = (num?: number) =>
  num && num !== 0 ? (
    <Tag
      style={styles.vipNavsItemTag}
      content={num < 0 ? '!' : num > 0 ? num : ''}
    />
  ) : null;

const HomeGoldArea = ({
  noticeMap,
  onNotice,
}: {
  noticeMap: NoticeMap;
  onNotice?: () => void;
}) => {
  const {i18n} = useTranslation();
  const [drawImage, setDrawImage] = useState<string>();
  const [freeCount, setFreeCount] = useState<number>(0);
  const [spinBatchCount, setSpinBatchCount] = useState(30);
  const [spinBasePrice, setSpinBasePrice] = useState(10);
  const onRefreshSpinConfig = () => {
    if (globalStore.token) {
      postSpinConfig(true).then(data => {
        setFreeCount(data?.myFree || 0);
      });
    }
  };
  const doNotice = () => {
    onNotice?.();
    onRefreshSpinConfig();
  };
  const {renderModal: renderSpin, show: spinShow} = useLuckySpinModal({
    drawImage,
    onNotice: doNotice,
    batchCount: spinBatchCount,
    singleAmount: spinBasePrice,
    freeCount: freeCount,
  });
  const {renderModal: renderLottery, show: lotteryShow} = useLotteryModal(
    () => {
      globalStore.globalTotal.next({
        type: 'success',
        message: i18n.t('home.tip.copied'),
      });
    },
  );
  const login = useRef<boolean>(false);
  useEffect(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      login.current = !!token;
      postSpinConfig(!!token).then(data => {
        setDrawImage(data?.image);
        setSpinBasePrice(data?.singleAmount);
        setSpinBatchCount(data?.batchCount);
        setFreeCount(data?.myFree || 0);
        Image.prefetch(data.image);
      });
      if (token) {
        postGetFreeLottery().then(lotteryInfo =>
          Image.prefetch(lotteryInfo.imgUrl),
        );
      }
    });
    const sub2 = globalStore.doNotices.subscribe(() => {
      doNotice();
    });
    return () => {
      sub.unsubscribe();
      sub2.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLotteryClick = async () => {
    if (!login.current) {
      goTo('Login');
      return;
    }
    // goTo('Referral');
    const lotteryInfo = await postGetFreeLottery();
    lotteryShow({
      current: lotteryInfo.num,
      total: lotteryInfo.maxNum,
      backgroundUrl: lotteryInfo.imgUrl,
      shareUrl: lotteryInfo.shareUrl,
      toolTipContent: lotteryInfo.contentStr,
    });
  };

  return (
    <View
      style={[
        theme.flex.row,
        {width: globalStore.screenWidth},
        theme.margin.btml,
        theme.flex.between,
      ]}>
      <NativeTouchableOpacity
        style={[theme.margin.lefts]}
        activeOpacity={0.8}
        onPress={() => {
          if (!globalStore.token) {
            goTo('Login');
            return;
          }
          if (globalStore.userInfo?.isAgent === 1) {
            goTo('ProxyHome');
          } else {
            toAgentApply();
          }
          // globalStore.globalLoading.next(true);
          // getAgentInfo()
          //   .then(res => {
          //     if (Object.keys(res).length === 0) {
          //       toAgentApply();
          //     } else {
          //       goTo('WebView', {
          //         path: '/proxy',
          //       });
          //     }
          //   })
          //   .finally(() => {
          //     globalStore.globalLoading.next(false);
          //   });
        }}>
        <View style={[styles.vipNavsItem, theme.flex.col, theme.flex.center]}>
          <View style={[theme.flex.flex1]}>
            <Image
              style={styles.vipNavsItemImg}
              source={require('@assets/gif/refer.gif')}
            />
          </View>
          <Text
            fontFamily="fontInterBold"
            fontSize={11}
            second
            style={styles.text}>
            {i18n.t('home.label.agent')}
          </Text>
        </View>
      </NativeTouchableOpacity>
      <NativeTouchableOpacity activeOpacity={0.8} onPress={spinShow}>
        <View style={[styles.vipNavsItem, theme.flex.col, theme.flex.center]}>
          <View style={[theme.flex.flex1]}>
            <Image
              style={styles.vipNavsItemImg}
              source={require('@assets/gif/luckyspin.gif')}
            />
          </View>
          <Text
            fontFamily="fontInterBold"
            fontSize={11}
            second
            style={styles.text}>
            {i18n.t('home.label.lucky-spin')}
          </Text>
          {getNavTag(noticeMap.LUCKY_SPIN)}
        </View>
      </NativeTouchableOpacity>
      <NativeTouchableOpacity
        activeOpacity={0.8}
        onPress={() => {
          goTo('Invitation');
        }}>
        <View
          style={[
            styles.vipNavsItem,
            theme.flex.col,
            theme.flex.center,
            theme.position.rel,
          ]}>
          <View style={[theme.flex.flex1]}>
            <Image
              style={styles.vipNavsItemImg}
              source={require('@assets/gif/rebate.gif')}
            />
          </View>
          <Text
            fontFamily="fontInterBold"
            fontSize={11}
            second
            style={styles.text}>
            {i18n.t('home.label.referFriend')}
          </Text>
        </View>
      </NativeTouchableOpacity>
      <NativeTouchableOpacity
        style={[theme.margin.rights]}
        activeOpacity={0.8}
        onPress={handleLotteryClick}>
        <View style={[styles.vipNavsItem, theme.flex.col, theme.flex.center]}>
          <View style={[theme.flex.flex1]}>
            <Image
              style={styles.vipNavsItemImg}
              source={require('@assets/gif/lottery.gif')}
            />
          </View>
          <Text
            second
            style={styles.text}
            fontSize={11}
            fontFamily="fontInterBold">
            {i18n.t('home.label.free-lottery')}
          </Text>
          {getNavTag(noticeMap.FREE_LOTTERY)}
        </View>
      </NativeTouchableOpacity>
      {renderSpin}
      {renderLottery}
    </View>
  );
};

export default HomeGoldArea;
