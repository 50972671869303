import Text from '@basicComponents/text';
import React from 'react';
import {View, Image} from 'react-native';
import theme from '@style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import globalStore from '@services/global.state';
import Tag from '@basicComponents/tag';
import {homeDrawerStyle} from '../home.style';
import {checkToken, goTo} from '@/utils';
import {useLanguageModal} from '@/components/business/language';
import {useTranslation} from 'react-i18next';
import {languagesMap} from '@/i18n';

const HomeMenu = (props: {
  setMenuOpen: (bool: boolean) => void;
  unreadCount?: number;
}) => {
  const {setMenuOpen, unreadCount} = props;
  const {i18n} = useTranslation();
  const {renderModal, show} = useLanguageModal();
  return (
    <View
      style={[
        theme.fill.fillW,
        theme.flex.col,
        theme.padding.l,
        {
          height: globalStore.screenHeight,
        },
      ]}>
      <View
        style={[
          theme.padding.tbl,
          theme.flex.flex,
          theme.flex.row,
          theme.flex.centerByRow,
        ]}>
        <Image
          style={[homeDrawerStyle.logo]}
          source={require('@assets/icons/logo-horizontal.webp')}
        />
        <View
          style={[theme.flex.flex1, theme.flex.row, theme.flex.centerByRow]}>
          <View style={[theme.flex.flex1]} />
          <NativeTouchableOpacity onPress={() => setMenuOpen(false)}>
            <Image
              style={[homeDrawerStyle.itemIcon]}
              source={require('@assets/icons/close.webp')}
            />
          </NativeTouchableOpacity>
        </View>
      </View>
      <NativeTouchableOpacity
        onPress={() => {
          setMenuOpen(false);
          if (checkToken()) {
            goTo('Invitation');
          }
        }}>
        <Image
          style={[
            theme.margin.tbxl,
            {
              width: globalStore.screenWidth * 0.7 - 24,
              height: (globalStore.screenWidth * 0.7 - 24) * (62 / 256),
            },
          ]}
          source={require('@assets/icons/home/refer-and-earn.webp')}
          resizeMode="contain"
        />
      </NativeTouchableOpacity>
      {[
        {
          icon: require('@assets/icons/home/casino.webp'),
          label: 'Casino',
          navTo: 'Casino',
        },
        {
          icon: require('@assets/icons/home/scratch.webp'),
          label: 'Scratch Off',
          navTo: 'Scratch',
        },
        {
          icon: require('@assets/icons/home/lottery.webp'),
          label: 'Lottery',
          navTo: 'HomeDetail',
          navParams: {detailType: 'kerala'},
        },
        // TODO 促销活动
        // {
        //   icon: require('@assets/icons/home/promotions.webp'),
        //   label: 'Promotions',
        // },
      ].map((v, i) => (
        <NativeTouchableOpacity
          key={i}
          onPress={() => {
            if (v.navTo) {
              setTimeout(() => {
                setMenuOpen(false);
              }, 16);
              goTo(v.navTo, v.navParams);
            } else {
              globalStore.globalTotal.next({
                type: 'warning',
                message: i18n.t('warning.unopen'),
              });
            }
          }}
          style={[
            theme.background.lightGrey,
            theme.padding.l,
            {
              marginBottom: theme.paddingSize.xxs / 2,
            },
          ]}>
          <View style={[theme.flex.row, theme.flex.centerByCol]}>
            <Image
              style={[homeDrawerStyle.itemIcon, theme.margin.rightl]}
              source={v.icon}
            />
            <Text fontSize={theme.fontSize.m}>{v.label}</Text>
          </View>
        </NativeTouchableOpacity>
      ))}
      {/* 消息模块 */}
      <NativeTouchableOpacity
        onPress={() => {
          setMenuOpen(false);
          if (checkToken()) {
            goTo('Notification');
          }
        }}
        style={[
          theme.background.lightGrey,
          theme.padding.l,
          theme.margin.topxxl,
        ]}>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Image
            style={[homeDrawerStyle.itemIcon, theme.margin.rightl]}
            source={require('@assets/icons/home/notifications.webp')}
          />
          <Text fontSize={theme.fontSize.m}>Notifications</Text>
          <View style={theme.flex.flex1} />
          {unreadCount != null && unreadCount > 0 && (
            <Tag content={unreadCount} />
          )}
        </View>
      </NativeTouchableOpacity>
      <NativeTouchableOpacity
        style={[
          theme.background.lightGrey,
          theme.padding.l,
          theme.margin.topxxl,
        ]}
        onPress={() => show()}>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Image
            style={[homeDrawerStyle.itemIcon, theme.margin.rightl]}
            source={require('@assets/icons/home/earth.webp')}
          />
          <Text fontSize={theme.fontSize.m}>
            {languagesMap[globalStore.lang]}
          </Text>
          <View style={theme.flex.flex1} />
          <Image
            style={[homeDrawerStyle.down]}
            source={require('@assets/icons/down.webp')}
          />
        </View>
      </NativeTouchableOpacity>
      {renderModal}
    </View>
  );
};

export default HomeMenu;
