import theme from '@style';
import React, {forwardRef} from 'react';
import {View, ViewProps} from 'react-native';
import HomeFloorBox from '../floor/home-floor-box';
import globalStore from '@/services/global.state';
import HomeKeralaCard from './home-kerala-card';
import {KeralaListItem} from '../../home.type';
import {goTo} from '@/utils';
import {useTranslation} from 'react-i18next';

interface HomeKeralaProps extends ViewProps {
  keralaList: KeralaListItem[];
}

const HomeKerala = forwardRef<View, HomeKeralaProps>((props, ref) => {
  const {i18n} = useTranslation();
  const {style, keralaList, ...otherProp} = props;
  // TODO 优化布局规则
  const cardWidth =
    (globalStore.screenWidth -
      theme.paddingSize.l * 2 -
      theme.paddingSize.s -
      1) /
    2;
  const imageHeight = (cardWidth * 114) / 172;
  return (
    <View
      ref={ref}
      {...otherProp}
      style={[{minHeight: imageHeight + 88 + theme.paddingSize.l}, style]}>
      <HomeFloorBox
        title={i18n.t('home.kerala.title')}
        list={keralaList}
        itemWidth={cardWidth}
        onPressViewAll={() => goTo('HomeDetail', {detailType: 'kerala'})}
        titleIcon={require('@assets/icons/home/little.webp')}
        titleIconSize={8}
        renderItem={(item, index) => {
          return (
            <HomeKeralaCard
              key={index}
              imageHeight={imageHeight}
              cardWidth={cardWidth}
              item={item}
              marginRight={index % 2 === 0}
              marginTop={index > 1}
            />
          );
        }}
      />
    </View>
  );
});

export default HomeKerala;
