import React from 'react';
import Text from '@basicComponents/text';
import Home from './pages/home';
import Me from './pages/me';
import theme from '@style';
import {
  BottomTabBarProps,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import {ImageURISource, Image} from 'react-native';
import Casino from './common-pages/casino';
import {NavigatorScreenProps} from '@types';
import LiveCasino from './common-pages/live-casino';
import {Shadow} from 'react-native-shadow-2';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import Scratch from './pages/scratch';
import {useTranslation} from 'react-i18next';

const Tab = createBottomTabNavigator();

export const mainPageList: {
  name: string;
  link: string;
  component: (props: NavigatorScreenProps) => React.JSX.Element;
  img: ImageURISource;
  activeImg: ImageURISource;
  unmountOnBlur?: boolean;
  hide?: boolean;
}[] = [
  {
    name: 'Home',
    link: 'index/home',
    component: Home,
    img: require('@assets/icons/home/home.webp'),
    activeImg: require('@assets/icons/home/home-active.webp'),
  },
  {
    name: 'Live',
    link: 'index/live',
    component: LiveCasino,
    img: require('@assets/icons/home/live.webp'),
    activeImg: require('@assets/icons/home/live-active.webp'),
  },
  {
    name: 'Casino',
    link: 'index/casino',
    component: Casino,
    img: require('@assets/icons/home/casino.webp'),
    activeImg: require('@assets/icons/home/casino-active.webp'),
  },
  {
    name: 'Scratch',
    link: 'index/scratch',
    component: Scratch,
    img: require('@assets/icons/home/scratch.webp'),
    activeImg: require('@assets/icons/home/scratch-active.webp'),
  },
  {
    name: 'Me',
    link: 'index/me',
    component: Me,
    img: require('@assets/icons/home/me.webp'),
    activeImg: require('@assets/icons/home/me-active.webp'),
  },
];

const CusTab = (props: BottomTabBarProps) => {
  return (
    <Shadow
      {...theme.shadow.defaultShadow}
      style={[
        theme.flex.row,
        theme.flex.around,
        theme.background.white,
        theme.fill.fillW,
        theme.shadow.defaultShadow.style,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          height: 56,
        },
      ]}>
      {props.state?.routes.map((route, index) => {
        const {options} = props.descriptors[route.key];
        const label =
          options.tabBarLabel !== undefined
            ? (options.tabBarLabel as string)
            : options.title !== undefined
            ? options.title
            : route.name;
        const isFocused = props.state.index === index;
        const onPress = () => {
          if (!isFocused) {
            props.navigation.navigate(route.name);
          }
        };
        return (
          <NativeTouchableOpacity
            key={label}
            activeOpacity={0.8}
            accessibilityRole="button"
            accessibilityState={isFocused ? {selected: true} : {}}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            testID={options.tabBarTestID}
            onPress={onPress}
            style={[theme.flex.center, theme.flex.flex1, theme.padding.tbs]}>
            <Image
              style={theme.icon.l}
              source={
                isFocused
                  ? mainPageList[index].activeImg
                  : mainPageList[index].img
              }
            />
            <Text
              blod={isFocused}
              fontSize={10}
              style={{
                color: isFocused
                  ? theme.basicColor.primary
                  : theme.basicColor.dark,
              }}>
              {label}
            </Text>
          </NativeTouchableOpacity>
        );
      })}
    </Shadow>
  );
};

const MainNav = () => {
  const {i18n} = useTranslation();
  return (
    <Tab.Navigator
      // eslint-disable-next-line react/no-unstable-nested-components
      tabBar={props => <CusTab {...props} />}
      screenOptions={{
        headerShown: false,
      }}>
      {mainPageList.map(v => (
        <Tab.Screen
          key={v.name}
          name={v.name}
          component={v.component}
          options={{
            title: i18n.t(`home.tab.${v.name.toLocaleLowerCase()}`),
            unmountOnBlur: v.unmountOnBlur,
          }}
        />
      ))}
    </Tab.Navigator>
  );
};

export default MainNav;
