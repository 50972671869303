import React, {useCallback, useEffect, useState} from 'react';
import {RefreshControl, View, ScrollView, Animated} from 'react-native';
import {BasicObject} from '@types';
import theme from '@style';
import {
  getDigitList,
  getBannerList,
  getKeralaList,
  getNoticeCheck,
  getDiceList,
  getMatkaList,
  // buyingMsg,
} from './home.service';
import globalStore from '@services/global.state';
import HomeMenu from './components/home-menu';
import HomeHeader from './components/home-header';
import Download from './components/download';
import HomeBanner from './components/home-banner';
import {
  BannerListItem,
  DiceListItem,
  DigitListItem,
  KeralaListItem,
  MatkaListItem,
} from './home.type';
import HomeGoldArea from './components/home-gold-area';
import {setDataForSettled, debounce} from '@/utils';
import HomeService from './components/home-service';
import {useFocusEffect} from '@react-navigation/native';
import {NoMoreData} from '@basicComponents/default-page';
import Spin from '@basicComponents/spin';
import Drawer, {DrawerRef} from '@basicComponents/drawer/drawer';
import Tabs from './components/tab/tab';
import HomeTabListContent from './home-list-tab-content';

const Home = () => {
  const menuRef = React.useRef<DrawerRef>(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const setMenuOpen = (bool: boolean, _unreadCount?: number) => {
    if (bool) {
      setUnreadCount(_unreadCount || 0);
      menuRef.current?.open();
    } else {
      menuRef.current?.close();
    }
  };
  const [noticeMap, setNoticeMap] = useState<BasicObject>({
    FREE_LOTTERY: 0,
    REBATE: 0,
    LUCKY_SPIN: 0,
  });
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [bannerList, setbannerList] = React.useState<BannerListItem[]>([]);
  const [keralaList, setKeralaList] = useState<KeralaListItem[]>([]);
  const [digitList, setDigitList] = useState<DigitListItem[]>([]);
  const [diceList, setDiceList] = useState<DiceListItem[]>([]);
  const [matkaList, setMatkaList] = useState<MatkaListItem[]>([]);
  const [pageLoading, setPageLoading] = React.useState(false);
  const [version, setVersion] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const topHeight = React.useRef(0);
  const getList = (refresh: boolean = false) => {
    if (!refresh) {
      setPageLoading(true);
    }

    Promise.allSettled([
      getBannerList(2),
      getKeralaList(),
      getDigitList(),
      getDiceList(),
      getMatkaList(),
    ])
      .then(([banner, kerala, digit, dice, matka]) => {
        setDataForSettled(setbannerList, banner);
        setDataForSettled(setKeralaList, kerala);
        setDataForSettled(setDigitList, digit);
        setDataForSettled(setDiceList, dice);
        setDataForSettled(setMatkaList, matka);
      })
      .finally(() => {
        setPageLoading(false);
        setRefreshing(false);
      });
  };
  const doNotice = (token: string | null) => {
    if (token) {
      getNoticeCheck().then(noticeList => {
        const newNoticeMap: BasicObject = {};
        Object.keys(noticeMap).forEach(key => {
          const notice = noticeList.find(item => item.noticeKey === key);
          if (notice) {
            newNoticeMap[key] = notice.noticeCount;
          }
        });
        setNoticeMap(newNoticeMap);
      });
    } else {
      setNoticeMap({
        FREE_LOTTERY: 0,
        REBATE: 0,
        LUCKY_SPIN: 0,
      });
    }
  };

  const handleScrollTo = debounce((e: {value: number}) => {
    handleScroll(e);
  }, 150);
  useEffect(() => {
    getList();
    scrollAnim.addListener(e => {
      handleScrollTo(e);
    });
    return () => {
      setRefreshing(false);
      scrollAnim.removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFocusEffect = useCallback(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      doNotice(token);
      globalStore.amountCheckOut.next();
      if (token) {
        globalStore.refreshNotification.next();
      }
    });
    // setTimeout(() => {
    //   // 这里的计时器是为了等待globalStore初始化token
    //   buyingMsg();
    // }, 0);
    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useFocusEffect(handleFocusEffect);
  const scrollViewRef = React.useRef<ScrollView>(null);
  const scrollAnim = React.useRef(new Animated.Value(0)).current;
  // const paddingL = theme.paddingSize.l;
  // const bannerHeight =
  //   ((globalStore.screenWidth - paddingL * 2) * 72) / 351 + paddingL * 2;
  // const goldAreaHeight = (81 * globalStore.screenWidth) / 375 + paddingL;
  // const tabTop = bannerHeight + goldAreaHeight;
  // const [measures, setMeasures] = React.useState<number[]>([]);
  const measures = React.useRef<number[]>([]);

  const handleScroll = (e: {value: number}) => {
    const {value: y} = e;
    // console.log(y, measures.current, topHeight.current, 'yyyy');
    // const tabHeight = 48;
    const totalTop = topHeight.current + 0;
    if (!totalTop) {
      return;
    }
    if (y >= totalTop + getToTopHeight(4)) {
      setActiveTab(4);
    } else if (y >= totalTop + getToTopHeight(3)) {
      setActiveTab(3);
    } else if (y >= totalTop + getToTopHeight(2)) {
      setActiveTab(2);
    } else if (y >= totalTop + getToTopHeight(1)) {
      setActiveTab(1);
    } else {
      setActiveTab(0);
    }
  };

  const getToTopHeight = (index: number) => {
    return measures.current
      .slice(0, index)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  };

  const scrollToCurrentGame = (index: number) => {
    // const tabHeight = 48;
    const totalTop = topHeight.current;
    scrollViewRef.current?.scrollTo({
      x: 0,
      y: getToTopHeight(index) + totalTop + 2,
      animated: true,
    });
  };

  return (
    <Spin loading={pageLoading} style={[theme.flex.col, theme.fill.fill]}>
      <View
        style={[
          theme.fill.fill,
          theme.flex.col,
          theme.background.lightGrey,
          theme.position.rel,
        ]}>
        <HomeHeader setMenuOpen={setMenuOpen} version={version} />
        {globalStore.isWeb && !globalStore.viewType ? <Download /> : null}
        <Animated.ScrollView
          ref={scrollViewRef}
          scrollEventThrottle={16}
          onScroll={Animated.event(
            [
              {
                nativeEvent: {
                  contentOffset: {
                    y: scrollAnim,
                  },
                },
              },
            ],
            {
              useNativeDriver: true,
            },
          )}
          // onTouchStart={() => {
          //   userScroll.current = true;
          // }}
          // onTouchEnd={setTouchEnd}
          style={[theme.flex.flex1]}
          stickyHeaderIndices={[1]}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => {
                setRefreshing(true);
                getList(true);
                doNotice(globalStore.token);
                setVersion(v => v + 1);
              }}
            />
          }>
          <View
            onLayout={e => {
              topHeight.current = e.nativeEvent.layout.height;
            }}>
            <HomeBanner bannerList={bannerList} />
            <HomeGoldArea
              noticeMap={noticeMap}
              onNotice={() => doNotice(globalStore.token)}
            />
          </View>
          <Tabs
            activeTab={activeTab}
            scrollToCurrentGame={scrollToCurrentGame}
          />
          <HomeTabListContent
            diceList={diceList}
            digitList={digitList}
            keralaList={keralaList}
            matkaList={matkaList}
            onMeasure={(index: number, anchor: number) => {
              measures.current[index] = anchor;
              measures.current = [...measures.current];
            }}
          />
          <NoMoreData text="" />
        </Animated.ScrollView>
        <HomeService />
      </View>
      <Drawer ref={menuRef}>
        <HomeMenu setMenuOpen={setMenuOpen} unreadCount={unreadCount} />
      </Drawer>
    </Spin>
  );
};

export default Home;
