import theme from '@/style';
import React from 'react';
import {View, Image, StyleSheet, Animated} from 'react-native';
import HomeFloorTitleBox from '../floor/home-floor-title-box';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
const gameTabs = ['Dice', '3 Digits', 'Color', 'Kerala', 'Matka'];

const Tabs = ({
  activeTab = 0,
  scrollToCurrentGame = () => {},
}: {
  scrollToCurrentGame: (index: number) => void;
  activeTab: number;
}) => {
  const tabAnim = React.useRef(new Animated.Value(0)).current;
  const [itemWidth, setItemWidth] = React.useState(0);

  const outputRange = React.useMemo(() => {
    return gameTabs.map((_, i) => i * itemWidth);
  }, [itemWidth]);

  React.useEffect(() => {
    console.log(activeTab, 'activeTab');
    Animated.timing(tabAnim, {
      toValue: activeTab,
      duration: 120,
      useNativeDriver: true,
    }).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    // eslint-disable-next-line react-native/no-inline-styles
    <View style={{height: 48}}>
      <HomeFloorTitleBox
        style={[theme.fill.fill]}
        titleBgImg={require('@assets/imgs/home/floor-bg.webp')}>
        <View
          style={[
            theme.flex.row,
            theme.flex.flex1,
            theme.padding.topxs,
            theme.padding.lrl,
            theme.flex.centerByCol,
          ]}>
          <Image
            style={[theme.icon.l, theme.margin.rights]}
            source={require('@assets/imgs/home/ball.webp')}
          />
          <View
            style={[theme.flex.row, theme.flex.flex1]}
            onLayout={e => {
              setItemWidth(e.nativeEvent.layout.width / 5);
            }}>
            {gameTabs.map((_, i) => (
              <NativeTouchableOpacity
                onPress={() => scrollToCurrentGame(i)}
                key={i}
                style={[theme.flex.flex1, theme.flex.center]}>
                <Text size="medium" blod={activeTab === i}>
                  {_}
                </Text>
              </NativeTouchableOpacity>
            ))}
          </View>
        </View>
      </HomeFloorTitleBox>
      <Animated.View
        style={[
          {width: itemWidth},
          styles.lineContainer,
          {
            transform: [
              {
                translateX: tabAnim.interpolate({
                  inputRange: [0, 1, 2, 3, 4],
                  outputRange: outputRange,
                }),
              },
            ],
          },
        ]}>
        <View style={[styles.line]} />
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  lineContainer: {
    position: 'absolute',
    bottom: 6,
    left: 44,
    justifyContent: 'center',
    alignItems: 'center',
  },
  line: {
    ...theme.background.primary,
    height: 4,
    width: 20,
  },
});

export default Tabs;
