import React from 'react';
import {BannerListItem} from '../home.type';
import globalStore from '@/services/global.state';
import theme from '@/style';
import Carousel from 'react-native-reanimated-carousel';
import {View, StyleSheet} from 'react-native';
import LazyImage from '@basicComponents/image';
import TouchableOpacity from '@basicComponents/touchable-opacity';
import Swiper from '../../../components/basic/swiper';
import {goToUrl} from '@/common-pages/game-navigate';

const HomeBanner = ({bannerList}: {bannerList: BannerListItem[]}) => {
  const bannerWidth = globalStore.screenWidth - theme.paddingSize.l * 2;
  const bannerHeight = (bannerWidth * 120) / 351;

  const [currentIndex, setCurrentIndex] = React.useState(0);
  const styles = StyleSheet.create({
    idotBox: {
      bottom: theme.paddingSize.xxl,
    },
    idot: {
      width: theme.paddingSize.xxs,
      height: theme.paddingSize.xxs,
      borderRadius: theme.paddingSize.xxs / 2,
      marginHorizontal: theme.paddingSize.xxs / 2,
      backgroundColor: theme.backgroundColor.palegrey,
    },
    idotActive: {
      backgroundColor: theme.basicColor.primary,
      width: theme.paddingSize.l,
    },
  });
  return globalStore.isWeb ? (
    <View style={[theme.padding.tbl]}>
      <Swiper
        pictureWidth={bannerWidth}
        seamless={true}
        paddingRight={theme.paddingSize.l}
        height={bannerHeight}
        autoPlay={true}
        hasIndicator={true}
        pictures={bannerList?.map((item: {bannerImg: string}) => ({
          uri: item.bannerImg,
        }))}
        onItemPress={_index => {
          goToUrl(bannerList[_index].skipLinks, bannerList[_index].title);
        }}
      />
    </View>
  ) : (
    <View style={[theme.position.rel, theme.padding.l]}>
      <Carousel
        loop
        style={[theme.borderRadius.m, theme.overflow.hidden]}
        width={bannerWidth}
        height={bannerHeight}
        autoPlay={true}
        autoPlayInterval={3000}
        scrollAnimationDuration={1000}
        data={bannerList}
        onProgressChange={e => {
          let index = Math.abs(Math.round(e / bannerWidth));
          if (index >= bannerList.length) {
            index = 0;
          }
          if (index !== currentIndex) {
            setCurrentIndex(index);
          }
        }}
        renderItem={({item}) => (
          <TouchableOpacity
            style={[theme.fill.fill]}
            onPress={() => {
              goToUrl(item.skipLinks, item.title);
            }}>
            <View
              style={[
                theme.fill.fill,
                theme.borderRadius.m,
                theme.overflow.hidden,
                theme.background.primary,
              ]}>
              <LazyImage
                occupancy={theme.backgroundColor.palegrey}
                imageUrl={item.bannerImg}
                height={bannerHeight}
                width={bannerWidth}
              />
            </View>
          </TouchableOpacity>
        )}
      />
      {!bannerList.length && (
        <View
          style={[
            // eslint-disable-next-line react-native/no-inline-styles
            {
              height: bannerHeight,
              width: bannerWidth,
              left: 12,
              top: 8,
            },
            theme.borderRadius.m,
            theme.position.abs,
            theme.background.palegrey,
          ]}
        />
      )}
      <View
        style={[
          theme.position.abs,
          theme.fill.fillW,
          theme.flex.row,
          theme.flex.center,
          styles.idotBox,
        ]}>
        {bannerList.map((_v, i) => (
          <View
            key={i}
            style={[styles.idot, currentIndex === i && styles.idotActive]}
          />
        ))}
      </View>
    </View>
  );
};

export default HomeBanner;
